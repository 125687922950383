import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer style={{ textAlign: "center", marginTop: ".5em" }}>
      {/* Links to LinkedIn and GitHub */}
      <a
        href="https://www.linkedin.com/in/begloff/"
        target="_blank"
        rel="noreferrer"
        style={{ marginRight: "1em" }}
      >
        <FontAwesomeIcon
          icon={faLinkedin}
          style={{ color: "#4d79c7" }}
          className="fa-2x"
        />
      </a>

      <a
        href="https://github.com/begloff"
        target="_blank"
        rel="noreferrer"
        style={{ marginLeft: "1em" }}
      >
        <FontAwesomeIcon
          icon={faGithubSquare}
          style={{ color: "#ffffff" }}
          className="fa-2x"
        />
      </a>

      <p style={{ marginTop: "1em", color: "#DADADA" }}>
        &copy; {new Date().getFullYear()} Ben Egloff | All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
