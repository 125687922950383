import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../config/firebase"; // Assuming you have initialized your Firebase app and have access to the Firestore instance
import { Card } from "react-bootstrap";
import { toTitleCase } from "../utils";
import { useNavigate } from "react-router-dom";
import { Box, Image } from "@chakra-ui/react";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const currentURI = window.location.pathname;
  const postId = currentURI.split("/posts/")[1];
  const headerTitle = postId ? toTitleCase(postId) + " Posts" : "Posts";
  const postFilter = postId ? postId : null;

  document.title = "Ben Egloff | Posts";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "posts"));
        const postsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const post = {
              id: doc.id,
              ...doc.data(),
            };
            return post;
          })
        );
        let filteredPosts = postsData;

        if (postFilter) {
          // If postFilter is not null, apply the filtering condition
          filteredPosts = postsData.filter(
            (post) => post.category === postFilter
          );
        }

        const shuffledPosts = filteredPosts.sort(() => Math.random() - 0.5);
        setPosts(shuffledPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();
  }, [postFilter]);

  const handlePostClick = (post) => {
    navigate(`/posts/${post.id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <div className="myRow">
        <div className="myCol">
          <h1 style={{ paddingBottom: "2%" }}>{headerTitle}</h1>
          <p>
            Many of the code bases and project documentation can be found on my
            github page{" "}
            <a
              href="https://github.com/begloff"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <hr style={{ marginTop: "40px", marginBottom: "40px" }} />
        </div>
      </div>

      {posts &&
        posts.map((post, index) => (
          <>
            <Box w={"100%"} px={4} display={{ base: "none", lg: "flex" }}>
              <Card
                style={{
                  width: "100%",
                  marginBottom: "35px",
                  boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.6)",
                  borderRadius: "8px",
                  backgroundColor: index % 2 === 0 ? "#242424" : "#1E1E1E",
                  cursor: "pointer",
                }}
                key={post.id}
                onClick={() => handlePostClick(post)}
              >
                <div className="myRow" style={{ marginTop: "20px" }}>
                  <Card.Title style={{ color: "#FFFFFF" }}>
                    {toTitleCase(post.title)}
                  </Card.Title>
                </div>
                <div className="myRow">
                  <Card.Text style={{ color: "#DADADA", fontSize: "14px" }}>
                    {`-${toTitleCase(post.category)}-`}
                  </Card.Text>
                </div>
                <div
                  className="myRow"
                  style={{
                    margin: "10px",
                    marginTop: "0",
                    alignItems: "center",
                  }}
                >
                  <div className="myCol">
                    <Card.Img
                      variant="top"
                      src={`../img/${post.id}/thumbnail.png`}
                      alt={post.id}
                      style={{
                        borderRadius: "8px 8px 8px 8px",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div
                    className="myCol"
                    style={{ flexBasis: "66%", maxHeight: "100%" }}
                  >
                    <Card.Body style={{ width: "100%", padding: "20px" }}>
                      {post.bullets && (
                        <ul
                          style={{
                            paddingLeft: "20px",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          {post.bullets.map((bullet, index) => {
                            const [firstPart, ...restParts] = bullet.split(":");
                            const rest = restParts.join(":").trim();

                            if (index === 2) {
                              const entries = rest.split(",");
                              return (
                                <li
                                  key={index}
                                  style={{ color: "#FFFFFF", padding: "5px" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {firstPart}
                                  </span>
                                  <ul
                                    style={{
                                      paddingLeft: "40px",
                                      fontSize: "14px",
                                      fontStyle: "italic",
                                      marginTop: "5px",
                                      listStyleType: "none",
                                      color: "#DADADA",
                                    }}
                                  >
                                    {entries.map((entry, entryIndex) => (
                                      <span
                                        key={entryIndex}
                                        style={{
                                          display: "inline-block",
                                          margin: "5px",
                                          padding: "5px 10px",
                                          borderRadius: "50px",
                                          background: "#5e5f61",
                                          color: "#FFFFFF",
                                        }}
                                      >
                                        {entry.trim()}
                                      </span>
                                    ))}
                                  </ul>
                                </li>
                              );
                            } else if (index < 2) {
                              return (
                                <li
                                  key={index}
                                  style={{ color: "#FFFFFF", padding: "5px" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {firstPart}
                                  </span>
                                  {rest && (
                                    <ul
                                      style={{
                                        paddingLeft: "40px",
                                        fontSize: "14px",
                                        fontStyle: "italic",
                                        marginTop: "5px",
                                        listStyleType: "none",
                                        color: "#DADADA",
                                      }}
                                    >
                                      {rest.split("\n").map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              );
                            } else {
                              return null; // Skip rendering for other indexes
                            }
                          })}
                        </ul>
                      )}
                    </Card.Body>
                  </div>
                </div>
              </Card>
            </Box>
            <Box w={"100%"} px={4} display={{ base: "flex", lg: "none" }}>
              <Card
                style={{
                  width: "100%",
                  marginBottom: "35px",
                  boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.6)",
                  borderRadius: "8px",
                  backgroundColor: index % 2 === 0 ? "#242424" : "#1E1E1E",
                  cursor: "pointer",
                }}
                key={post.id}
                onClick={() => handlePostClick(post)}
              >
                <div className="myRow" style={{ marginTop: "20px" }}>
                  <Card.Title style={{ color: "#FFFFFF" }}>
                    {toTitleCase(post.title)}
                  </Card.Title>
                </div>
                <div className="myRow">
                  <Card.Text style={{ color: "#DADADA", fontSize: "14px" }}>
                    {`-${toTitleCase(post.category)}-`}
                  </Card.Text>
                </div>
                <Image
                  src={`../img/${post.id}/thumbnail.png`}
                  alt={post.id}
                  style={{
                    borderRadius: "8px 8px 8px 8px",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "cover",
                  }}
                  w={"100%"}
                  h={"200px"}
                  objectFit={"cover"}
                />
                <div
                  className="myRow"
                  style={{
                    margin: "10px",
                    marginTop: "0",
                    alignItems: "center",
                  }}
                >
                  <div className="myCol" style={{ maxHeight: "100%" }}>
                    <Card.Body style={{ width: "100%", padding: "20px" }}>
                      {post.bullets && (
                        <ul
                          style={{
                            paddingLeft: "20px",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          {post.bullets.map((bullet, index) => {
                            const [firstPart, ...restParts] = bullet.split(":");
                            const rest = restParts.join(":").trim();

                            if (index === 2) {
                              const entries = rest.split(",");
                              return (
                                <li
                                  key={index}
                                  style={{ color: "#FFFFFF", padding: "5px" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {firstPart}
                                  </span>
                                  <ul
                                    style={{
                                      paddingLeft: "40px",
                                      fontSize: "14px",
                                      fontStyle: "italic",
                                      marginTop: "5px",
                                      listStyleType: "none",
                                      color: "#DADADA",
                                    }}
                                  >
                                    {entries.map((entry, entryIndex) => (
                                      <span
                                        key={entryIndex}
                                        style={{
                                          display: "inline-block",
                                          margin: "5px",
                                          padding: "5px 10px",
                                          borderRadius: "50px",
                                          background: "#5e5f61",
                                          color: "#FFFFFF",
                                        }}
                                      >
                                        {entry.trim()}
                                      </span>
                                    ))}
                                  </ul>
                                </li>
                              );
                            } else if (index < 2) {
                              return (
                                <li
                                  key={index}
                                  style={{ color: "#FFFFFF", padding: "5px" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {firstPart}
                                  </span>
                                  {rest && (
                                    <ul
                                      style={{
                                        paddingLeft: "40px",
                                        fontSize: "14px",
                                        fontStyle: "italic",
                                        marginTop: "5px",
                                        listStyleType: "none",
                                        color: "#DADADA",
                                      }}
                                    >
                                      {rest.split("\n").map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              );
                            } else {
                              return null; // Skip rendering for other indexes
                            }
                          })}
                        </ul>
                      )}
                    </Card.Body>
                  </div>
                </div>
              </Card>
            </Box>
          </>
        ))}
    </div>
  );
};

export default Posts;
