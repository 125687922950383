import { Image, Flex, Box, HStack, VStack } from "@chakra-ui/react";

const Aboutme = () => {
  document.title = "Ben Egloff | About Me";
  const generateAlternatingColors = (text) => {
    const letters = text.split("");
    return letters.map((letter, index) => (
      <span
        key={index}
        style={{ color: index % 2 === 0 ? "#5D7BFF" : "#FFF77A" }}
      >
        {letter}
      </span>
    ));
  };
  const generateAlternatingColors2 = (text) => {
    const letters = text.split("");
    return letters.map((letter, index) => (
      <span
        key={index}
        style={{ color: index % 2 === 0 ? "#5D7BFF" : "#FF5D5D" }}
      >
        {letter}
      </span>
    ));
  };

  return (
    <div>
      <h1 style={{ marginTop: "30px" }}>Hi, I'm Ben Egloff! 👋</h1>
      <Flex
        style={{ marginTop: "60px" }}
        flexDirection={{ base: "column", lg: "row" }}
        alignItems={{ base: "center" }} // Align items to center on base and flex-start on md and larger
      >
        <Box
          w={{ base: "100%", md: "100%", lg: "40%", xl: "30%" }}
          justifyContent={{ base: "center", md: "flex-start" }}
          mb={{ base: 4, md: 4, lg: 0 }}
        >
          <Image
            src={"../img/aboutme/Headshot.jpg"}
            alt=""
            style={{ borderRadius: "12px", margin: "auto" }} // Center the image horizontally
            w={{ base: "70%", md: "70%", lg: "100%" }}
          />
        </Box>

        <VStack
          ml={{ base: "0", md: 6 }}
          flex={{ base: "1", md: "55%", lg: "60%", xl: "70%" }}
          alignItems={{ base: "center", md: "flex-start" }} // Align items to center on base and flex-start on md and larger
        >
          <p style={{ fontSize: "20px", marginBottom: "1.5%" }}>
            I'm a graduate of the{" "}
            <a target="_blank" rel="noreferrer" href="https://www.nd.edu/">
              University of Notre Dame
            </a>{" "}
            with a degree in Computer Science. I have a passion for software
            development and am constantly seeking new opportunities to learn and
            grow as a developer.
          </p>
          <p style={{ fontSize: "20px", marginBottom: "1.5%" }}>
            Creating something from scratch has always been a source of joy for
            me. The prospect of developing tools that{" "}
            <b>empower various industries</b> and improve lives is what
            initially attracted me to the field of Software Engineering.
          </p>
          <p style={{ fontSize: "20px", marginBottom: "1.5%" }}>
            Project experiences and education have fueled my curiosity and love
            for computer science and coding. As I look ahead, I'm excited to{" "}
            <b>apply my skills and knowledge</b> to shape an exciting career in
            the tech world.
          </p>
          <p style={{ fontSize: "20px", marginBottom: "1.5%" }}>
            I'm also a firm believer in the power of{" "}
            <b>teamwork and collaboration</b>. I thrive in environments where
            ideas are shared and nurtured, and exceptional products are made{" "}
            <b>together</b>.
          </p>
        </VStack>
      </Flex>

      <hr style={{ marginTop: "60px", marginBottom: "40px" }} />

      <div className="myRow">
        <Image
          src={"../img/aboutme/family.jpg"}
          alt=""
          style={{ borderRadius: "12px 12px 12px 12px" }}
          w={{ base: "70%", md: "50%", lg: "45%", xl: "30%" }}
        />
      </div>
      <div className="myRow">
        <div className="myCol">
          <p style={{ fontSize: "15px", marginTop: "2%" }}>
            I was born and raised in Buffalo, NY and truly hold{" "}
            <b>"the city of good neighbors"</b> close to my heart. I am
            extremely grateful for my parents and their choice to raise me in
            such a wonderful city with such a strong support system. I feel like
            I am certainly a product of my environment and have my family to
            thank for my strong work ethic and drive to succeed.
          </p>
        </div>
      </div>

      <hr style={{ marginTop: "40px", marginBottom: "40px" }} />
      <Flex
        w={"100%"}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Flex
          mb={{ base: 2, md: 0 }}
          pr={{ base: 0, md: 2 }}
          w={"100%"}
          flexDirection={{ base: "row", md: "column" }}
          flex={"1"}
        >
          <Box display={{ base: "flex", md: "none" }} flexBasis={"45%"}></Box>
          <Box flexBasis={{ base: "55%", md: "0%" }}>
            <Image
              src="../img/aboutme/snowboard.jpg"
              alt=""
              style={{ borderRadius: "12px 12px 12px 12px" }}
              h={"auto"}
            />
          </Box>
        </Flex>
        <Flex
          flex={{ base: "0", md: "30%" }}
          px={{ base: 0, md: 2 }}
          my={{ base: 2, md: 0 }}
          w={"100%"}
          flexDirection={{ base: "row", md: "column" }}
        >
          <Box flexBasis={{ base: "80%", md: "0%" }}>
            <Image
              src="../img/aboutme/golf.jpg"
              alt=""
              style={{ borderRadius: "12px 12px 12px 12px" }}
              h={"auto"}
            />
          </Box>
          <Box display={{ base: "flex", md: "none" }} flexBasis={"20%"}></Box>
        </Flex>
        <Flex
          mt={{ base: 2, md: 0 }}
          pl={{ base: 0, md: 2 }}
          w={"100%"}
          flexDirection={{ base: "row", md: "column" }}
          flex={"1"}
        >
          <Box display={{ base: "flex", md: "none" }} flexBasis={"45%"}></Box>
          <Box flexBasis={{ base: "55%", md: "0%" }}>
            <Image
              src="../img/aboutme/waterski.jpg"
              alt=""
              style={{ borderRadius: "12px 12px 12px 12px" }}
              h={"auto"}
            />
          </Box>
        </Flex>
      </Flex>
      <div className="myRow">
        <div className="myCol">
          <p style={{ fontSize: "15px", marginTop: "2%" }}>
            In addition to my passion for coding, I love to be active and enjoy
            time with friends and family. During the winter, I am an avid
            snowboarder and hockey fan
            {generateAlternatingColors(" (Go Sabres) ")}
            and during the summer I love to golf and waterski. I would also be
            completely remiss if I didn't mention my love for the{" "}
            {generateAlternatingColors2(" Buffalo Bills ")} - a superbowl is
            coming soon.
          </p>
        </div>
      </div>
      <hr style={{ marginTop: "40px", marginBottom: "40px" }} />
      <HStack>
        <Box style={{ marginLeft: "5px", marginRight: "5px" }} flex={4.5}>
          <Image
            src="../img/aboutme/friends3.jpg"
            alt=""
            style={{ width: "100%", borderRadius: "12px 12px 12px 12px" }}
          />
        </Box>
        <Box style={{ marginLeft: "5px", marginRight: "5px" }} flex={8}>
          <Image
            src="../img/aboutme/friends.jpg"
            alt=""
            style={{ width: "100%", borderRadius: "12px 12px 12px 12px" }}
          />
        </Box>
        <Box flex={0.1}></Box>
      </HStack>
      <HStack style={{ marginTop: "20px" }}>
        <Box flex={0.5}></Box>
        <Box style={{ marginLeft: "5px", marginRight: "5px" }} flex={7}>
          <Image
            src="../img/aboutme/friends4.jpg"
            alt=""
            style={{ width: "100%", borderRadius: "12px 12px 12px 12px" }}
          />
        </Box>
        <Box style={{ marginLeft: "5px", marginRight: "5px" }} flex={4.5}>
          <Image
            src="../img/aboutme/friends5.jpg"
            alt=""
            style={{ width: "100%", borderRadius: "12px 12px 12px 12px" }}
          />
        </Box>
      </HStack>
      <div className="myRow">
        <div className="myCol">
          <p style={{ fontSize: "15px", marginTop: "2%" }}>
            Although Buffalo is, and always will be my home, I have been
            extremely fortunate to have had the opportunity to study at Notre
            Dame. I have met my best friends and have had the opportunity to
            learn from some of the best professors in the world. I am{" "}
            <b>extremely grateful for my time at ND</b> and will always be a
            proud member of the Notre Dame family and the Duncan Hall community.
          </p>
        </div>
      </div>
      <hr style={{ marginTop: "40px", marginBottom: "40px" }} />
      <div className="myRow">
        <div className="myCol">
          <p style={{ fontSize: "15px", marginBottom: "1%" }}>
            I have had the opportunity to meet some amazing people and have
            learned a lot along the way. I am excited to continue to{" "}
            <b>learn and grow</b> as a developer and as a person.
          </p>
          <p style={{ fontSize: "15px", marginBottom: "1%" }}>
            I am truly excited to see where my career takes me and am looking to
            take on new challenges and opportunities. I am currently looking for
            a full-time position in software and would love to connect with you!
            Please feel free to reach out to me at any time via{" "}
            <a
              href="https://www.linkedin.com/in/begloff/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            .
          </p>
          <p
            style={{ fontSize: "20px", marginBottom: "1%", marginTop: "40px" }}
          >
            In all, I am a <b>hard-working</b>, <b>dedicated</b>, and{" "}
            <b>passionate</b> individual who is excited to begin his career in
            the tech world.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutme;
