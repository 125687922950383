import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom"; // Import the Link component

const myNavbar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="#121212" variant="dark">
      <Container>
        <Link to="/" className="navbar-brand">
          Ben Egloff
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Link to="/about" className="nav-link">
              About Me
            </Link>
            <Link to="/posts/projects" className="nav-link">
              Projects
            </Link>
            <Link to="/posts/education" className="nav-link">
              Education
            </Link>
            <Link to="/posts" className="nav-link">
              All Posts
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default myNavbar;
