import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import { toTitleCase } from "./utils";
import PostsComponent from "./Home"; // Import your fallback component here

const PostLoader = () => {
  const { id } = useParams();
  const title = toTitleCase(id);
  const DynamicPostComponent = React.lazy(() =>
    import(`./pages/${title}.js`).catch(() => ({ default: PostsComponent }))
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <DynamicPostComponent />
    </Suspense>
  );
};

export default PostLoader;
