import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../config/firebase"; // Assuming you have initialized your Firebase app and have access to the Firestore instance
import { Card } from "react-bootstrap";
import { toTitleCase } from "../utils";
import { useNavigate } from "react-router-dom";
import { Box, Image } from "@chakra-ui/react";

const PostsPreview = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "posts"));
        const postsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const post = {
              id: doc.id,
              ...doc.data(),
            };
            return post;
          })
        );
        const shuffledPosts = postsData.sort(() => Math.random() - 0.5);
        setPosts(shuffledPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();
  }, []);

  const handlePostClick = (post) => {
    navigate(`/posts/${post.id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
    >
      <h1 style={{ paddingBottom: "2%", width: "100%" }}>Posts</h1>

      {posts &&
        posts.map((post, index) => {
          // Extract the first sentence of the first bullet point
          const firstBullet = post.bullets && post.bullets[0];
          const firstSentence =
            (firstBullet ? firstBullet.split(".")[0] : "").split(": ")[1] + ".";

          return (
            <>
              <Box w={"100%"} px={4} display={{ base: "none", md: "flex" }}>
                <Card
                  style={{
                    width: "100%", // Set the width to distribute cards across the two columns
                    marginBottom: "35px",
                    boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.6)",
                    borderRadius: "8px",
                    backgroundColor: index % 2 === 0 ? "#242424" : "#1E1E1E",
                    marginLeft: "2.5%", // Add some margin between cards in the columns
                    marginRight: "2.5%",
                    cursor: "pointer",
                  }}
                  key={post.id}
                  onClick={() => handlePostClick(post)}
                >
                  <div
                    className="myRow"
                    style={{ margin: "10px", alignItems: "center" }}
                  >
                    <div className="myCol">
                      {/* For base use the current layout, but for md and large redesign to make title, cover image, and baseline text */}
                      <Image
                        src={`../img/${post.id}/thumbnail.png`}
                        alt={post.id}
                        style={{
                          borderRadius: "8px 8px 8px 8px",
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div
                      className="myCol"
                      style={{ flexBasis: "40%", maxHeight: "100%" }}
                    >
                      <Card.Body style={{ width: "100%", padding: "20px" }}>
                        <Card.Title style={{ color: "#FFFFFF" }}>
                          {post.title}
                        </Card.Title>
                        <Card.Text
                          style={{ color: "#DADADA", fontSize: "16px" }}
                        >
                          {`-${toTitleCase(post.category)}-`}
                        </Card.Text>
                      </Card.Body>
                      <Card.Body style={{ width: "100%", padding: "20px" }}>
                        <Card.Text
                          style={{ color: "#DADADA", fontSize: "15px" }}
                        >
                          {firstSentence}
                        </Card.Text>
                      </Card.Body>
                    </div>
                  </div>
                </Card>
              </Box>
              <Box
                w={{ base: "100%", md: "45%", xl: "30%" }}
                px={4}
                display={{ base: "flex", md: "none" }}
              >
                <Card
                  style={{
                    width: "100%", // Set the width to distribute cards across the two columns
                    marginBottom: "35px",
                    boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.6)",
                    borderRadius: "8px",
                    backgroundColor: index % 2 === 0 ? "#242424" : "#1E1E1E",
                    marginLeft: "2.5%", // Add some margin between cards in the columns
                    marginRight: "2.5%",
                    cursor: "pointer",
                    flex: "1",
                  }}
                  key={post.id}
                  onClick={() => handlePostClick(post)}
                >
                  <Card.Body
                    style={{
                      width: "100%",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Card.Title style={{ color: "#FFFFFF", flex: "1" }}>
                      {post.title}
                    </Card.Title>
                    <Image
                      src={`../img/${post.id}/thumbnail.png`}
                      alt={post.id}
                      style={{
                        borderRadius: "8px 8px 8px 8px",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                      }}
                      w={"100%"}
                      h={"200px"}
                      objectFit={"cover"}
                    />
                    <Card.Text
                      style={{ color: "#DADADA", fontSize: "16px", flex: "1" }}
                    >
                      {`-${toTitleCase(post.category)}-`}
                    </Card.Text>
                    <Card.Text
                      style={{ color: "#DADADA", fontSize: "15px", flex: "1" }}
                    >
                      {firstSentence}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Box>
            </>
          );
        })}
    </div>
  );
};

export default PostsPreview;
