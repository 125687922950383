import Proficiencies from "./components/Proficiencies";
import PostsPreview from "./components/PostsPreview";
import LessFamiliarTech from "./components/LessFamiliarTech";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";

const Home = () => {
  document.title = "Ben Egloff | Home";

  return (
    <div>
      <h2 style={{ marginTop: "8vh", marginBottom: "4vh", fontSize: "4em" }}>
        Welcome to my Portfolio! 💻
      </h2>

      {/* Short bio */}

      <p style={{ fontSize: "1.5em", marginBottom: "4vh" }}>
        My name is <b style={{ color: "white" }}>Ben Egloff</b> and I am an
        aspiring software engineer and graduate of the{" "}
        <a target="_blank" rel="noreferrer" href="https://www.nd.edu/">
          University of Notre Dame
        </a>
        . ☘️
      </p>
      <p>
        This coding portfolio site is a detailed look into my projects,
        education, and background relating to my love and passion for Computer
        Science.
      </p>
      <p>
        Each post on this website outlines my educational efforts in an
        extensive and comprehensive way that demonstrate my skills in various
        programming languages and areas of work.
      </p>
      <p>
        I invite you to explore my projects and witness the dedication, passion
        and effort I put into each line of <code>code</code>.
      </p>

      {/* Links to linkedIn and github */}

      <a
        href="https://www.linkedin.com/in/begloff/"
        target="_blank"
        rel="noreferrer"
        style={{ marginRight: "5vw" }}
      >
        <FontAwesomeIcon
          icon={faLinkedin}
          style={{ color: "#4d79c7" }}
          className="fa-3x"
        />
      </a>

      <a
        href="https://github.com/begloff"
        target="_blank"
        rel="noreferrer"
        style={{ marginLeft: "5vw" }}
      >
        <FontAwesomeIcon
          icon={faGithubSquare}
          style={{ color: "#ffffff" }}
          className="fa-3x"
        />
      </a>

      <hr />

      {/* Proficiencies and Areas of Experience */}
      <Proficiencies />

      <LessFamiliarTech />
      <hr />
      {/* Categorized posts */}
      <PostsPreview />
    </div>
  );
};

export default Home;
