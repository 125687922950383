import { Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const LessFamiliarTech = () => {
  const [techData, setTechData] = useState([]);

  const TechItem = ({ tech }) => {
    const [expanded, setExpanded] = useState(false);

    const handleMouseEnter = () => {
      setExpanded(true);
    };

    const handleMouseLeave = () => {
      setExpanded(false);
    };

    return (
      <Box w={{ base: "100%", md: "45%", xl: "30%" }}>
        <a
          href={tech.redirect}
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#adadad",
            textDecoration: "none",
          }}
        >
          <div
            key={tech.name}
            style={{
              width: "100%",
              marginBottom: "35px",
              boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.6)",
              borderRadius: "8px",
              backgroundColor: tech.index % 2 === 0 ? "#242424" : "#1E1E1E",
              marginLeft: "10px",
              marginRight: "10px",
              height: expanded ? "160px" : "80px",
              display: "grid",
              gridTemplateRows: expanded ? "80px auto" : "80px", // Adjust the gridTemplateRows
              alignItems: "center",
              transition: "height 0.3s ease", // Add transition for height
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={"cardStyle"}
          >
            <div
              className="myRow"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <div
                className="myCol"
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={tech.logo}
                  alt={tech.name}
                  style={{
                    height: "80%",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div className="myCol" style={{ flexBasis: "20%" }}>
                <h3 style={{ fontSize: "20px" }}>{tech.name}</h3>
              </div>
            </div>
            <div
              className="myRow"
              style={{
                overflow: "hidden",
                maxHeight: expanded ? "60px" : "0", // Set max-height to control animation
                transition: "max-height 0.3s ease", // Add transition for max-height
              }}
            >
              <Box>
                <ul
                  style={{
                    textAlign: "center", // Center align the text
                    color: "#DADADA",
                    fontSize: "15px",
                    width: "100%",
                    paddingLeft: "10px", // Remove default left padding
                    listStylePosition: "inside", // Position bullet points inside the list item
                  }}
                >
                  <li style={{ textAlign: "left" }}>
                    Familiarity: {tech.familiarity}
                  </li>
                  <li style={{ textAlign: "left" }}>
                    Experience: {tech.experience}
                  </li>
                </ul>
              </Box>
            </div>
          </div>
        </a>
      </Box>
    );
  };

  useEffect(() => {
    const lessFamiliarTech = [
      {
        name: "Django",
        familiarity: "Basic",
        experience: "Project",
        type: "Framework",
        logo: "https://gitlab.com/uploads/-/system/project/avatar/31295677/pngwing.com__1_.png",
        redirect: "https://www.djangoproject.com/",
      },
      {
        name: "React",
        familiarity: "Intermediate",
        experience: "Internship",
        type: "Framework",
        logo: "https://ionicframework.com/docs/icons/logo-react-icon.png",
        redirect: "https://reactjs.org/",
      },
      {
        name: "React Native",
        familiarity: "Basic",
        experience: "Internship",
        type: "Framework",
        logo: "https://seeklogo.com/images/E/expo-logo-01BB2BCFC3-seeklogo.com.png",
        redirect: "https://reactnative.dev/",
      },
      {
        name: "Php",
        familiarity: "Basic",
        experience: "Internship",
        type: "Language",
        logo: "https://pngimg.com/d/php_PNG34.png",
        redirect: "https://www.php.net/",
      },
      {
        name: "Express",
        familiarity: "Basic",
        experience: "Internship",
        type: "Framework",
        logo: "https://cdn.icon-icons.com/icons2/2699/PNG/512/expressjs_logo_icon_169185.png",
        redirect: "https://expressjs.com/",
      },
      {
        name: "C++",
        familiarity: "Basic",
        experience: "Project",
        type: "Language",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1822px-ISO_C%2B%2B_Logo.svg.png",
        redirect: "https://www.cplusplus.com/",
      },
    ];
    const shuffled = lessFamiliarTech.sort(() => 0.5 - Math.random());
    setTechData(shuffled);
  }, []);

  return (
    <div>
      <hr style={{ marginTop: "40px", marginBottom: "40px" }} />
      <h3>Other Technologies & Frameworks</h3>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "20px",
          justifyContent: "center",
        }}
      >
        {techData.map((tech) => (
          <TechItem key={tech.name} tech={tech} />
        ))}
      </div>
    </div>
  );
};
export default LessFamiliarTech;
