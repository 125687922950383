export function toTitleCase(str) {
  if (str === "duncangrille") {
    return "Duncan Grille";
  } else if (str === "p2phashtable") {
    return "P2P Hashtable";
  } else if (str === "fullstackdeveloperinternship") {
    return "Intern - Full Stack Development";
  } else if (str === "projects") {
    return "Project";
  } else {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
