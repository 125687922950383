import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MyNavbar from "./components/myNavbar.js";
import Home from "./Home";
import MissingRoute from "./MissingRoute";
import PostLoader from "./PostLoader";
import BackToTopButton from "./components/BackToTopButton";
import Posts from "./components/Posts";
import Aboutme from "./pages/Aboutme";
import Footer from "./components/Footer";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        fontFamily: "body",
        color: "white",
        bg: "#121212",
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <div className="App">
          <div className="header">
            <MyNavbar />
          </div>

          <Box w={"100%"} px={{ base: "10%", md: "20%" }}>
            <Box className="content">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="*" element={<MissingRoute />} />
                <Route path="/posts/:id" element={<PostLoader />} />
                <Route path="/posts" element={<Posts />} />
                <Route path="/posts/education" element={<Posts />} />
                <Route path="/posts/projects" element={<Posts />} />
                <Route path="/about" element={<Aboutme />} />
              </Routes>
            </Box>
            <BackToTopButton />
            <Footer />
          </Box>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;
