import { Box } from "@chakra-ui/react";

const Proficiencies = () => {
  return (
    <Box>
      <Box
        className="proficiencies"
        display={{ base: "none", md: "flex" }}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <div className="myRow" style={{ paddingBottom: "20px" }}>
          <div className="myCol">
            <h3 style={{ marginTop: "2vh", marginBottom: "5vh" }}>
              Language Proficiencies
            </h3>
          </div>
          <div className="myCol">
            <h3 style={{ marginTop: "2vh", marginBottom: "5vh" }}>
              Study Interests
            </h3>
          </div>
        </div>

        <div className="myRow" style={{ paddingBottom: "20px" }}>
          <div className="myCol">
            <div className="myRow">
              <div className="myCol">
                <li
                  className="myList"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.python.org/"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      Python
                    </a>
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.python.org/"
                  style={{ fontWeight: "normal", color: "inherit" }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList2"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://vuejs.org/guide/introduction.html"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      Vue.js
                    </a>
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://vuejs.org/guide/introduction.html"
                >
                  <img
                    src="https://vuejs.org/images/logo.png"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList3"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.gnu.org/software/gnu-c-manual/gnu-c-manual.html"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      C
                    </a>
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.gnu.org/software/gnu-c-manual/gnu-c-manual.html"
                  style={{ fontWeight: "normal", color: "inherit" }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/C_Programming_Language.svg/695px-C_Programming_Language.svg.png"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList4"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontWeight: "normal", color: "inherit" }}
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML"
                    >
                      HTML
                    </a>{" "}
                    /{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontWeight: "normal", color: "inherit" }}
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                    >
                      CSS
                    </a>
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://developer.mozilla.org/en-US/docs/Web/HTML"
                  style={{ fontWeight: "normal", color: "inherit" }}
                >
                  <img
                    src="https://www.svgrepo.com/show/452228/html-5.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                  style={{ fontWeight: "normal", color: "inherit" }}
                >
                  <img
                    src="https://www.svgrepo.com/show/452185/css-3.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList5"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://dev.mysql.com/doc/"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      SQL
                    </a>
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev.mysql.com/doc/"
                  style={{ fontWeight: "normal", color: "inherit" }}
                >
                  <img
                    src="https://www.svgrepo.com/show/255832/sql.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList6"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      JavaScript
                    </a>
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                  style={{ fontWeight: "normal", color: "inherit" }}
                >
                  <img
                    src="https://www.svgrepo.com/show/353925/javascript.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="myCol">
            <div
              className="myRow"
              style={{ marginBottom: "5vh", paddingBottom: "20px" }}
            >
              <div className="myCol">
                <li
                  className="myList7"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code
                    style={{
                      display: "inline",
                      color: "#FF6F00",
                      fontSize: "24px",
                    }}
                  >
                    Web Development
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src="https://www.svgrepo.com/show/241766/web-development.svg"
                  alt=""
                  style={{ maxHeight: "6vh" }}
                />
              </div>
            </div>

            <div
              className="myRow"
              style={{ marginBottom: "5vh", paddingBottom: "20px" }}
            >
              <div className="myCol">
                <li
                  className="myList8"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code
                    style={{
                      display: "inline",
                      color: "#FF6F00",
                      fontSize: "24px",
                    }}
                  >
                    Database & API Management
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src="https://www.svgrepo.com/show/261923/database-server.svg"
                  alt=""
                  style={{ maxHeight: "6vh" }}
                />
              </div>
            </div>

            <div
              className="myRow"
              style={{ marginBottom: "5vh", paddingBottom: "20px" }}
            >
              <div className="myCol">
                <li
                  className="myList9"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code
                    style={{
                      display: "inline",
                      color: "#FF6F00",
                      fontSize: "24px",
                    }}
                  >
                    Systems Programming
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src="https://www.svgrepo.com/show/331744/operating-system-image.svg"
                  alt=""
                  style={{ maxHeight: "6vh" }}
                />
              </div>
            </div>

            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList10"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code
                    style={{
                      display: "inline",
                      color: "#FF6F00",
                      fontSize: "24px",
                    }}
                  >
                    Networking
                  </code>
                </li>
              </div>
              <div
                className="myCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src="https://www.svgrepo.com/show/506898/cld-cloud-computer-network.svg"
                  alt=""
                  style={{ maxHeight: "6vh" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>

      <Box
        display={{ base: "flex", md: "none" }}
        textAlign={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <h3
          style={{
            marginTop: "2vh",
            marginBottom: "5vh",
          }}
        >
          Language Proficiencies
        </h3>
        <div className="myRow" style={{ paddingBottom: "20px", width: "100%" }}>
          <div className="myCol">
            <div className="myRow">
              <div className="myCol">
                <li
                  className="myList"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.python.org/"
                      style={{
                        fontWeight: "normal",
                        color: "inherit",
                        alignContent: "center",
                      }}
                    >
                      Python
                    </a>
                  </code>
                </li>
              </div>
              <div className="myCol">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.python.org/"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "normal",
                    color: "inherit",
                    maxHeight: "6vh",
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList2"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://vuejs.org/guide/introduction.html"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      Vue.js
                    </a>
                  </code>
                </li>
              </div>
              <div className="myCol">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://vuejs.org/guide/introduction.html"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "normal",
                    color: "inherit",
                    maxHeight: "6vh",
                  }}
                >
                  <img
                    src="https://vuejs.org/images/logo.png"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList3"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.gnu.org/software/gnu-c-manual/gnu-c-manual.html"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      C
                    </a>
                  </code>
                </li>
              </div>
              <div className="myCol">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.gnu.org/software/gnu-c-manual/gnu-c-manual.html"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "normal",
                    color: "inherit",
                    maxHeight: "6vh",
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/C_Programming_Language.svg/695px-C_Programming_Language.svg.png"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList4"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontWeight: "normal", color: "inherit" }}
                      href="https://developer.mozilla.org/en-US/docs/Web/HTML"
                    >
                      HTML
                    </a>{" "}
                    /{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontWeight: "normal", color: "inherit" }}
                      href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                    >
                      CSS
                    </a>
                  </code>
                </li>
              </div>
              <div className="myCol">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://developer.mozilla.org/en-US/docs/Web/HTML"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "normal",
                    color: "inherit",
                    maxHeight: "6vh",
                  }}
                >
                  <img
                    src="https://www.svgrepo.com/show/452228/html-5.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://developer.mozilla.org/en-US/docs/Web/CSS"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "normal",
                    color: "inherit",
                    maxHeight: "6vh",
                  }}
                >
                  <img
                    src="https://www.svgrepo.com/show/452185/css-3.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList5"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://dev.mysql.com/doc/"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      SQL
                    </a>
                  </code>
                </li>
              </div>
              <div className="myCol">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev.mysql.com/doc/"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "normal",
                    color: "inherit",
                    maxHeight: "6vh",
                  }}
                >
                  <img
                    src="https://www.svgrepo.com/show/255832/sql.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="myRow" style={{ paddingBottom: "20px" }}>
              <div className="myCol">
                <li
                  className="myList6"
                  style={{ listStyleType: "none", textAlign: "right" }}
                >
                  <code style={{ fontSize: "24px" }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                      style={{ fontWeight: "normal", color: "inherit" }}
                    >
                      JavaScript
                    </a>
                  </code>
                </li>
              </div>
              <div className="myCol">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "normal",
                    color: "inherit",
                    maxHeight: "6vh",
                  }}
                >
                  <img
                    src="https://www.svgrepo.com/show/353925/javascript.svg"
                    style={{ maxHeight: "6vh" }}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <h3 style={{ marginTop: "2vh", marginBottom: "5vh" }}>
          Study Interests
        </h3>
        <div
          className="myRow"
          style={{ marginBottom: "5vh", paddingBottom: "20px", width: "100%" }}
        >
          <div className="myCol">
            <li
              className="myList7"
              style={{ listStyleType: "none", textAlign: "right" }}
            >
              <code
                style={{
                  display: "inline",
                  color: "#FF6F00",
                  fontSize: "24px",
                }}
              >
                Web Development
              </code>
            </li>
          </div>
          <div
            className="myCol"
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "normal",
              color: "inherit",
              maxHeight: "6vh",
            }}
          >
            <img
              src="https://www.svgrepo.com/show/241766/web-development.svg"
              alt=""
              style={{ maxHeight: "6vh" }}
            />
          </div>
        </div>

        <div
          className="myRow"
          style={{ marginBottom: "5vh", paddingBottom: "20px", width: "100%" }}
        >
          <div className="myCol">
            <li
              className="myList8"
              style={{ listStyleType: "none", textAlign: "right" }}
            >
              <code
                style={{
                  display: "inline",
                  color: "#FF6F00",
                  fontSize: "24px",
                }}
              >
                Database & API Management
              </code>
            </li>
          </div>
          <div
            className="myCol"
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "normal",
              color: "inherit",
              maxHeight: "6vh",
            }}
          >
            <img
              src="https://www.svgrepo.com/show/261923/database-server.svg"
              alt=""
              style={{ maxHeight: "6vh" }}
            />
          </div>
        </div>

        <div
          className="myRow"
          style={{ marginBottom: "5vh", paddingBottom: "20px", width: "100%" }}
        >
          <div className="myCol">
            <li
              className="myList9"
              style={{ listStyleType: "none", textAlign: "right" }}
            >
              <code
                style={{
                  display: "inline",
                  color: "#FF6F00",
                  fontSize: "24px",
                }}
              >
                Systems Programming
              </code>
            </li>
          </div>
          <div
            className="myCol"
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "normal",
              color: "inherit",
              maxHeight: "6vh",
            }}
          >
            <img
              src="https://www.svgrepo.com/show/331744/operating-system-image.svg"
              alt=""
              style={{ maxHeight: "6vh" }}
            />
          </div>
        </div>

        <div className="myRow" style={{ paddingBottom: "20px", width: "100%" }}>
          <div className="myCol">
            <li
              className="myList10"
              style={{ listStyleType: "none", textAlign: "right" }}
            >
              <code
                style={{
                  display: "inline",
                  color: "#FF6F00",
                  fontSize: "24px",
                }}
              >
                Networking
              </code>
            </li>
          </div>
          <div
            className="myCol"
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "normal",
              color: "inherit",
              maxHeight: "6vh",
            }}
          >
            <img
              src="https://www.svgrepo.com/show/506898/cld-cloud-computer-network.svg"
              alt=""
              style={{ maxHeight: "6vh" }}
            />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Proficiencies;
